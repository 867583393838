import { graphql } from 'gatsby'
import React from 'react'
import { BlogPost } from '../Blog/BlogPost'
import { toPlainText } from '../helpers'
import { Seo } from '../utils'

export default function BlogTemplate({ data }) {
  const post = data && data.post
  return (
    <>
      {post && (
        <Seo
          title={post.title || 'Untitled'}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}
      {post && <BlogPost {...post} />}
    </>
  )
}

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      pixelTag
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
  }
`
