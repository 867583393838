import React, { useState } from 'react'
import styled from 'styled-components'
import { format, parseISO, formatDistance, differenceInDays } from 'date-fns'
import {
  buildImageObj,
  callBuild,
  imageUrlFor,
  useQuickBuilderState,
} from '../helpers'
import { PortableText, rgba, Typography } from '../utils'
import { AuthorList } from './AuthorList'
import de from 'date-fns/locale/de'

export function BlogPost({
  _rawBody,
  authors,
  title,
  mainImage,
  pixelTag,
  publishedAt,
}) {
  return (
    <Article>
      {pixelTag && <PixelTag src={pixelTag} width={1} height={1} alt="" />}
      <Container>
        {mainImage && mainImage.asset && (
          <MainImage>
            <img
              src={imageUrlFor(buildImageObj(mainImage))
                .width(1200)
                .height(Math.floor((9 / 17.14) * 1200))
                .fit('max')
                .auto('format')
                .url()}
              alt={mainImage.alt}
            />
          </MainImage>
        )}
        <MainContent>
          <Typography variant="h1">{title}</Typography>
          <MetaContent>
            {authors && <AuthorList items={authors} />}
            {publishedAt && (
              <PublishedAt>
                {differenceInDays(new Date(parseISO(publishedAt)), new Date()) >
                3
                  ? formatDistance(new Date(parseISO(publishedAt)), new Date())
                  : format(new Date(parseISO(publishedAt)), 'dd. MMMM yyyy', {
                      locale: de,
                    })}
              </PublishedAt>
            )}
          </MetaContent>
          {_rawBody && <PortableText blocks={_rawBody} />}
        </MainContent>
      </Container>
    </Article>
  )
}

const Article = styled.article`
  padding: 5rem 0;
  color: ${({ theme }) => theme.primary};
  background-color: white;
  position: relative;
`

const Container = styled.div`
  box-sizing: border-box;
  max-width: 960px;
  padding: 1.5rem;
  margin: 0 auto;

  @media (min-width: 450px) {
    padding: 2rem;
  }
`

const MainImage = styled.figure`
  text-align: center;
  display: block;
  position: relative;
  // background: $color-very-light-gray;
  background-color: white;
  padding-bottom: calc(9 / 17.14 * 100%);
  // max-width: 70%;
  // max-width: 70%;
  height: auto;
  margin: 0 auto 2rem;
  @media (max-width: 420px) {
    margin: 0 auto 1rem;
  }

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

const MainContent = styled.div`
  max-width: 675px;
  margin: 0 auto;

  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;

  h1 {
    text-align: center;
    @media (max-width: 420px) {
      font-size: 2rem;
    }
  }

  a {
    color: ${({ theme }) => theme.secondary};
    text-decoration: none;
    font-size: 18px;
    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin: 1rem 0 0.5rem;
    line-height: 120%;
  }

  figure {
    margin: 0.5rem 0 1rem;
    padding: 0;

    img {
      max-width: 100%;
    }
  }

  .blogpostYoutube {
    margin: 0;
    margin-bottom: 1.45rem;
    padding-top: 56.25%;
    position: relative;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  ul {
    list-style: disc;
  }

  blockquote {
    color: ${({ theme }) => rgba(theme.primary, 0.8)};
    font-style: italic;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    a {
      font-size: 20px;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 2.25rem 0 0.75rem;
    }
  }
`

const MetaContent = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 675px) {
    display: grid;
    // grid-template-columns: 1fr;
    grid-column-gap: 2em;
    grid-template-columns: 1fr 1fr;
  }
  margin-bottom: 2rem;
`

const PublishedAt = styled.div`
  font-size: 0.9rem;
  line-height: 1.5;
  align-self: center;
  color: ${({ theme }) => rgba(theme.primary, 0.8)};
  /* color: ${({ theme }) => theme.color.labelText}; */
`

const PixelTag = styled.img`
  width: 1px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
`
